@import 'styles/main';

.commissions {
  & .Modal {
    & > div:nth-child(2) {
      width: max-content !important;
      max-width: 80vw;
      min-width: min-content;
      height: 90vh !important;
    }
    & .container {
      height: 95%;
      display: flex;
      flex-direction: column;
      & h1 {
        @extend %reset;
        width: 100%;
        text-align: center;
        color: $grey;
      }
      & > div {
        width: 100%;
        height: 100% !important;
        overflow-y: hidden;
      }
    }
  }
}
