@import 'styles/main';

.text {
  & h1 {
    font-size: 22px;
    width: 100%;
    text-align: center;
    color: $black;
    margin-bottom: 5px;
  }
  & p {
    text-align: center;
    margin: 0px auto 20px;
    color: $grey;
  }
}
