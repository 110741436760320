@import 'styles/main';

.alert {
  @extend .notification;
  bottom: 0;
  top: auto;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
