@import 'styles/main';

.date {
  margin: 0 auto;
  color: $grey;
  font-size: 14px;
  & .expired {
    color: $semantic-danger !important;
  }
}
