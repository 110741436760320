@import 'styles/main';
.detailContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.question {
  font-weight: 550;
}

.img{
  width: 100%;
  height: auto;
  object-fit: scale-down;
  margin-bottom: 10px;
}

.videoWrapper {
  margin-top: 15px;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  background: #000;

  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.closeButton {
  position: absolute;
  top: 10px; 
  right: 10px; 
  background-color:$grey-lighter; 
  color: #fff; 
  border: none;
  border-radius: 50%;
  width: 25px; 
  height: 25px; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.closeButton:hover {
  background-color: $grey-light; 
}
