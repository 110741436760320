@import 'styles/main';

.statuses {
  display: flex;
  flex-direction: row;
  gap: 12px;

  .cardContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .statesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .rows {
    display: flex;
    flex-direction: row;
    gap: 8px;


  }

  // Estilos para cada estado
  .status {
    display: flex;
    align-items: center;
    gap: 8px;

    & span {
      font-size: calc(#{$font-size-body} - 2px);
      font-weight: 500;
    }

    & div {
      height: 11px;
      width: 11px;
      border-radius: 100%;
    }
  }

  // Estilos para los diferentes estados
  .confirmed {
    background-color:$blue;
  }

  .pending {
    background-color: #e3ad31;
  }

  .confirmedSelected {
    background-color: white;
    border: 1px solid $grey;
  }
}
// Media query para pantallas menores a 612px