@import 'styles/main';

.hiddenSideSection {
  flex-grow: 1;
  border: 2px dashed $grey-lighter;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  & img {
    filter: grayscale(100);
    width: 250px;
  }
  & > div:last-child {
    width: 300px;
    text-align: center;
    color: $grey-light;
    font-weight: 500;
    margin-top: 20px;
  }
}

