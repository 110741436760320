@import 'styles/main';

.marketingView {
  width: 100%;
  & .titles {
    & > h1,
    & > h2 {
      @extend %reset;
    }
    & > h1 {
      color: $primary-color;
      font-size: calc(#{$font-size-body} + 2px);
    }
    & > h2 {
      color: $grey;
      font-weight: 400;
      font-size: 18px;
    }
  }
  & .cards {
    & > div {
      display: inline-flex;
      margin-right: 20px;
      margin-top: 20px;
    }
  }
}
