@import 'styles/main';

.carrierResources {
  height: calc(100vh - 75px);
  display: grid;
  gap: 15px;
  position: relative;

  & .carrierResourcesItem {
    grid-area: carrierResources;
    overflow: auto;
    position: relative;
    display: block;
  }

  & .detailsItem {
    grid-area: details;
    overflow: auto;
    display: block;
  }

  @include for-tablet-portrait-down {
    .detailsItem {
      display: none;
    }
    grid-template-areas: 'carrierResources';
    &.isActive {
      grid-template-areas: 'details';
      & .carrierResourcesItem {
        display: none;
      }
      & .detailsItem {
        display: block;
      }
    }
  }
  @include for-tablet-portrait-up {
    & .detailsItem {
      display: none;
    }
    grid-template-areas: 'carrierResources';
    &.isActive {
      grid-template-areas: 'details';
      & .carrierResourcesItem {
        display: none;
      }
      & .detailsItem {
        display: block;
      }
    }
  }
  @include for-tablet-landscape-up {
    grid-template-areas: 'carrierResources details' !important;
    grid-template-columns: 1fr minmax(auto, 400px) !important;
    grid-template-rows: 1fr !important;
    & .detailsItem {
      display: block !important;
    }
    & .carrierResourcesItem {
      display: block !important;
    }
  }
  @include for-desktop-up {
    grid-template-areas: 'carrierResources details';
    grid-template-columns: 1fr minmax(auto, 400px);
    grid-template-rows: 1fr;
  }

  & .sideSection {
    // padding: 10px;
    & h2 {
      @extend %reset;
      font-size: $font-size-body;
      color: black;
      margin-bottom: 10px;
      margin-top: 12px;
    }
    & .description {
      & p {
        @extend %reset;
        margin-top: 10px;
        color: $grey;
        text-align: left;
      }
    }
    & .contactInfo {
      width: 100%;
      margin: 0 auto;
      border-bottom: 1px dashed $grey-lighter;
      padding-bottom: 20px;
      & > div {
        margin-top: 10px;
      }
    }
    & .externalLinks {
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        & span {
          color: black;
          font-weight: 400;
        }
      }
    }
    & .noInfo {
      font-weight: 600;
      font-size: 20px;
      width: 80%;
      text-align: center;
      margin: 50% auto 0;
    }
  }
  & .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  & .faqs{
    display: flex;
    flex-direction: column;
  }
  p {
    margin-top: 0;
  }

}
