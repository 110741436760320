@import 'styles/main';

.dropdown {
  & .header {
    position: relative;
    & > a {
      padding: 10px 0;
      padding-left: 25px;
    }
    & > svg {
      position: absolute;
      height: 12px;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      // transition: 0.1s all;
      & * {
        fill: $grey-light;
      }
    }
    &:hover > svg * {
      fill: $grey;
    }
  }
  & .body {
    overflow-y: hidden;
    max-height: 0;
    // transition: 0.1s all;
    & > a {
      padding: 10px 0;
      padding-left: 45px;
    }
  }
  &.active {
    & .header {
      & > svg {
        transform: translateY(-50%) rotate(90deg);
        transform-origin: center;
      }
    }
    & .body {
      max-height: 1000px;
    }
  }
  &.selected {
    .header svg * {
      fill: $primary-color-emphasis;
    }
  }
}
