.autocomplete {
  input {
    border: none;
  }
  &.showScrollbar {
    ul {
      -ms-overflow-style: auto !important; /* IE and Edge */
      scrollbar-width: auto !important; /* Firefox */
    }
  }
}
