@import 'styles/main';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h4 {
  margin: 0;
}

.filtersContainerMain {
  height: 100%;
}

.filtersContainer {
  border-radius: 14px;
  border: 1px solid #d9d9da;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 1200px) {
    max-height: calc(100vh - 86px);
  }
}

.tableHeight {
  min-height: calc(100vh - 166px) !important;
  max-height: calc(100vh - 166px) !important;
}

.potentialMatchesTableHeight {
  min-height: 430px !important;
  max-height: 430px !important;
}

.wrapButtonsSmall {
  @media screen and (max-width: 560px) {
    flex-wrap: wrap;
  }
}

.filtersHeader {
  display: flex;
  background-color: $grey-lightest;
  align-items: center;
  padding: 15px;
  gap: 15px;
}

.filters {
  background-color: $white;
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.clearApplyFilters {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  padding: 16px 0px;
  position: sticky;
  top: 0px;
  background-color: $white;
  z-index: 2;
  width: 100%;
}

.arrowRightTransition {
  transform: rotate(0deg);
}

.searchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.arrowRightTransitionActive {
  transform: rotate(180deg);
}

.tableContainer {
  border-radius: 14px;
  border: 1px solid #d9d9da;
  overflow: hidden;
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.react-table-column-flex-grow-1 {
  flex-grow: 1 !important;
  width: unset !important;
  flex-basis: 5px !important;
  max-width: none !important;
}

.react-table-head-content > div {
  width: auto !important;
}

.buttonNoWrap div {
  white-space: nowrap;
}

.buttonPaddingLeft {
  padding-left: 10px;
}
