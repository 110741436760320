@import 'styles/main';

.container {
  all: unset;
  height: 280px !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.span {
  font-size: $font-size-body;
}
