// Mobile Size
// sm: 575.98px,
// md: 767.98px,

//Table Size
// lg: 991.98px,

//Desktop
// xl: 1199.98px,
// xxl: 1399.98px

@mixin for-phone-only {
  @media (max-width: 575.98px) {
    @content;
  }
}
@mixin for-tablet-portrait-down {
  @media (max-width: 767.98px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 767.98px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 991.98px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1199.98px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1690px) {
    @content;
  }
}
