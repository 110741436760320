@import 'styles/main';

.menuLink {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  // transition: 200ms all;
  // & * {
  //   transition: 200ms all;
  // }
  & svg {
    $size: 16px;
    width: $size;
    height: $size;
    // object-fit: cover;
    margin-right: 20px;
    & * {
      fill: grey;
    }
    fill: $grey-light;
  }
  & span {
    color: $grey;
    font-size: calc(#{$font-size-body});
    font-weight: 300;
    height: min-content;
    padding: 0;
  }
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0px;
    background: $primary-color-darkened;
    top: 0;
    right: 0;
    opacity: 0;
    // transition: 200ms all;
  }
  &:hover {
    background-color: $primary-color-light;
    & span {
      color: $primary-color-darkened;
    }
    & svg * {
      fill: $primary-color-darkened;
    }
    transition: 0.1s all;
  }
  &.active {
    background: $primary-color-light;
    //
    & span {
      color: $primary-color-darkened;
      font-weight: 400;
    }
    & svg * {
      fill: $primary-color-darkened;
    }
    &::after {
      width: 5px;
      top: 0;
      opacity: 1;
    }
  }
}
