.mainSectionAppointments {
  background-color: white;
  padding: 20px 0;
  width: 65%;
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  & header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 15px;
    align-items: center;
    border-bottom: 1px solid $grey-lightest;
    & > h1 {
      @extend %reset;
      font-size: 18px;
      color: $black;
    }
    & > div {
      display: flex;
      & button {
        font-size: 14px;
        padding: 10px 30px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  & main {
    padding: 15px 20px 0;
    overflow-y: auto;
    height: 90%;
  }
}

// .sideSection {
//   height: 100%;
//   flex-grow: 1;
//   margin-left: 20px;
//   border-radius: 10px;
//   background-color: $white;
//   overflow-y: auto;
//   & header {
//     display: flex;
//     justify-content: space-between;
//     padding: 20px;
//     & > h2 {
//       @extend %reset;
//       font-size: 18px;
//     }
//     & button {
//       background-color: transparent;
//       border: none;
//       & svg {
//         cursor: pointer;
//         height: 25px;
//         padding: 5px 15px;
//         background: $primary-color-light;
//         transition: 200ms all;
//         border-radius: 5px;
//       }
//       &:hover > svg {
//         background-color: $primary-color-emphasis-light;
//         & * {
//           fill: #fff;
//         }
//       }
//     }
//   }
// }

.genericForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
}

//TABLE
.tableContainer {
  position: relative;
  z-index: 0 !important;
  & > div:last-child {
    box-shadow: none;
    & > :nth-child(1) {
      //toolbar
      min-height: auto;
      padding: 10px 0px;
    }
    & > :nth-child(2) {
      thead > tr > th {
        background-color: rgba(230, 230, 230, 0.2);
        font-weight: 500;
        padding: 6px 6px;
        text-align: left;
      }
      tbody > tr {
        & > td {
          border-bottom: 1px dashed $grey-lighter;
          padding: 14px 10px;
        }
        &:hover {
          background-color: #feffea90;
        }
      }
    }
    & > :nth-child(3) {
      //footer
      & * {
        border: none;
      }
      & div {
        gap: 5px;
      }
      & button {
        cursor: pointer;
        padding: 0;
      }
      & button:not([disabled]) {
        & > span:first-child {
          background-color: $primary-color-light;
          border-radius: 5px;
          & * {
            color: $primary-color;
          }
        }
      }
    }
  }
}
#agencyDashboardAgentsTableContainer {
  & > div:last-child {
    & > :nth-child(1) {
      padding: 0px;
    }
  }
}
.tableButton {
  padding: 4px 0;
  font-size: 16px;
  margin: 0 auto;
  text-transform: capitalize;
  min-width: max-content;
  // width: 110px;
  font-size: 14px;
}

.notification {
  // position: fixed;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  // border-radius: 5px;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
  // overflow: hidden;
  // width: max-content;
  // height: 0px;
  // padding: 0;
  // z-index: 998;
  // color: #fff;
  // background-color: transparent;
  // transition: 200ms all;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 998;
  overflow: hidden;
  transition: 200ms all;
  height: 0px;
  & > span {
    font-weight: 600;
    color: inherit;
    text-transform: capitalize;
  }
  &.active {
    padding: 5px 20px;
    height: 35px;
  }

  &.error {
    background-color: $semantic-danger;
  }
  &.info {
    background-color: $semantic-info;
  }
  &.success {
    background-color: $semantic-success;
  }
  &.warning {
    background-color: #e37d00;
  }
}

.modalActionItems {
  & > div:nth-child(2) {
    min-height: 100px;
    height: min-content;
    max-height: 60vh;
  }
}

.select-none {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

label {
  span:last-child {
    font-family: $font-family-primary !important;
  }
}
