@import 'styles/main';

.mainLayout {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0px 10px 10px;
  // background-color: #f3f4f6;
  background-color: $grey-background;
  & .bodyContainer {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px 10px;
    &.noHeader {
      padding-top: 0;
    }
    &.noFooter {
      padding-bottom: 0;
    }
  }
  &.noScroll {
    max-height: 100vh;
    & .bodyContainer {
      max-height: 100vh;
      overflow: hidden;
    }
    overflow: hidden;
  }
  @include for-tablet-portrait-down {
    padding: 0px;
    & .bodyContainer {
      padding: 5px 15px;
    }
  }
}
