@import 'styles/main';

.btnOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 10px;
  & button:last-child {
    margin-top: 5px;
  }
}

.labelImportant {
  font-weight: 600;
}
