@import 'styles/main';

.carousel {
  position: relative;
  min-height: 220px;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  background-color: white;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 18px;
  }

  & > div {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    & span {
      text-transform: capitalize;
      font-size: $font-size-caption;
      font-weight: 600;
    }
    & > span:first-child {
      position: absolute;
      top: 10px;
      left: 20px;
    }
    & .texts {
      width: 100%;
      position: absolute;
      bottom: 40px;
      padding-left: 20px;
      & > span {
        font-size: $font-size-note;
        color: black;
      }
      & > p {
        width: 40%;
        font-size: $font-size-note;
        margin: 0;
        padding: 0;
      }
    }
  }

  & .indicators {
    position: absolute;
    bottom: 15px;
    left: 20px;
    display: flex;
    & span {
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $white;
      display: block;
      margin-right: 10px;
      cursor: pointer;
      &.active {
        background-color: $secondary-color;
      }
    }
  }

  & .gradient {
    background: linear-gradient(
      90deg,
      rgba(246, 246, 246, 0.97) 30%,
      rgba(246, 246, 246, 0) 65%
    );
  }

  & .whiteText {
    & > span,
    & > p {
      color: white !important;
    }
  }
}
