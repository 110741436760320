@import 'styles/main';

.taskCard {
  background-color: $white;
  width: 100%;
  padding: 5px 0px;
  transition: 200ms all;
  &:hover {
    cursor: default;
  }
  &.clickable {
    &:hover {
      cursor: pointer;
      background-color: rgba($color: $grey-lightest, $alpha: 0.7);
    }
  }

  & p {
    margin: 0;
    padding: 0;
    font-size: calc(#{$font-size-body} - 1px);
    text-align: left;
    line-height: 1.4;
    color: $grey;
    margin-bottom: 10px;
  }
  & .dueDate {
    color: $grey-light !important;
  }
  & .expired {
    color: $semantic-danger !important;
  }
  & .open {
    color: $secondary-color-darkened !important;
  }
  &.pastTask {
    opacity: 0.8;
  }
  .taskHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    // flex-direction: row;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    & .calendar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 70px;
      // height: 65px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      border-radius: 5px;
      & .day {
        font-size: 18px;
        line-height: 1;
        margin: 5px 0px;
        font-weight: 400;
      }
      & .month {
        font-size: 12px;
        margin: 5px 0px;
        line-height: 0;
        font-weight: 400;
      }
      & .year {
        font-size: 12px;
        line-height: 1;
        margin: 5px 0px;
        font-weight: 400;
        & svg {
          width: 20px;
          height: 20px;
        }
      }
      &.calendarExpired {
        @extend .calendar;
        background-color: rgba(
          $color: $semantic-danger,
          $alpha: 0.1
        ) !important;
        // border: 1px dashed $semantic-danger;
        color: $semantic-danger !important;
      }
      &.calendarOpen {
        @extend .calendar;
        background-color: rgba(
          $color: $secondary-color-darkened,
          $alpha: 0.1
        ) !important;
        color: $secondary-color-darkened !important;
      }
    }
    & .title {
      font-size: calc(#{$font-size-body});
      color: black;
      min-width: max-content;
      font-weight: 500;
      margin-bottom: 10px;
      line-height: 0.5;
      word-wrap: break-word;
    }
    & .owner {
      display: flex;
      align-items: center;
      gap: 5px;

      & svg {
        width: 16px;
        height: 16px;
      }
      & div {
        font-size: 14px;
        font-weight: 400 !important;
        color: $grey !important;
      }
    }
  }
}
