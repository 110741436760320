@import 'styles/main';

.card {
  height: 365px;
  min-width: 310px;
  padding-bottom: 10px;
 
}
.containter{
  overflow-y: auto;
  height: 285px;
}
.questionList {
  list-style-type: none; /* Quita los puntos de la lista */
  padding: 0;
  margin: 0;
}
.questionItem {
  cursor: pointer;
  margin-bottom: 10px;
  font-size: calc($font-size-body - 2px);
  transition: background 0.3s ease;
  padding-left: 2px;

  &:hover {
    background-color: $primary-color-light;
    & span {
      color: $primary-color-darkened;
    }
    & svg * {
      fill: $primary-color-darkened;
    }
    transition: 0.1s all;
    
  }

  &.selected {
    background: $primary-color-light;
    border-right: 5px solid $primary-color-darkened;
    //
    & span {
      color: $primary-color-darkened;
      font-weight: 400;
    }
    & svg * {
      fill: $primary-color-darkened;
    }
  }
}
