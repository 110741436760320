@import 'styles/main';

.secondaryPage {
  position: relative;
  height: 100vh;
  width: 100vw;
  & .backArrow {
    position: absolute;
    top: 30px;
    left: 30px;
  }
}
