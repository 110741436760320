@import 'styles/main';

.actionItems {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 15px;
  overflow: hidden;
  background-color: $white;
  z-index: 1;
  position: relative;
}
