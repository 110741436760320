@import 'styles/main';
.login {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .formContainer {
    width: 400px;
    min-height: 100px;
    height: max-content;
    background: $white;
    border-radius: 15px;
    padding: 20px 45px;
    & img {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 55px;
      display: block;
      margin-bottom: 20px;
    }
  }

  @include for-tablet-portrait-down {
    & .formContainer {
      margin: auto 15px;
    }
  }
}
.submitButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}
.description {
  font-size: 0.875rem;
  text-align: justify;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  color: #9e9e9e;
}
.subTitle {
  color: #2b6cb0;
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
}