@import 'styles/main';

.sendEmail {
  & p {
    font-size: 20px;
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
  & button {
    margin: 30px auto 0;
  }
}
