@import '../../styles/main.scss';

.dashboard {
  display: grid;
  gap: 15px;
  width: 100%;
  $height: calc(100vh - 75px);
  .carouselItem {
    grid-area: carousel;
    display: flex;
  }
  .toolsItem {
    grid-area: tools;
  }
  .infoItem {
    grid-area: info;
  }
  .cardsItem {
    display: grid;
    gap: 15px;
  }
  .notesItem {
    grid-area: notes;
    overflow: hidden;
  }
  .pendingTasksItem {
    grid-area: pendingTasks;
    overflow: hidden;
  }

  & .buttons {
    display: grid;
    grid-template-columns: auto auto auto auto;
    // grid-template-columns: repeat(4, auto-fill);
    gap: 15px;
    & a,
    button {
      width: 100%;
      height: 100%;
    }
  }

  @include for-tablet-portrait-down {
    height: auto;
    grid-template-areas:
      'carousel'
      'info'
      'notes';
    grid-template-columns: auto;
    & .buttons {
      grid-template-columns: auto;
    }
    & .cardsItem {
      grid-template-columns: auto;
    }
  }
  @include for-tablet-portrait-up {
    height: auto;
    grid-template-areas:
      'carousel'
      'info'
      'notes';
    grid-template-columns: 1fr;
    grid-template-rows: 222px auto 1fr minmax(1fr, 600px) minmax(1fr, 600px);
    & .buttons {
      grid-template-columns: auto;
    }
    & .cardsItem {
      grid-template-columns: 1fr 1fr;
    }
  }
  @include for-tablet-landscape-up {
    height: auto;
    grid-template-areas:
      'carousel carousel'
      'tools tools'
      'info notes';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 222px auto minmax(auto, 550px) 1fr;
    & .buttons {
      grid-template-columns: auto;
    }
    & .cardsItem {
      grid-template-columns: auto;
    }
  }
  @include for-desktop-up {
    height: $height;
    grid-template-areas:
      'carousel carousel carousel notes'
      'info info info notes';
    grid-template-columns: 0.95fr 0.95fr 0.95fr minmax(min-content, 1.15fr);
    grid-template-rows: 300px auto;
    & .cardsItem {
      grid-template-columns:  1fr 1fr 1fr;
    }
  }
  @media (min-width: 1280px) {
    // height: $height;
    // grid-template-areas:
    //   'carousel carousel'
    //   'tools tools'
    //   'info info'
    //   'notes pendingTasks';
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: 220px auto auto 1fr;
    // & .buttons {
    //   grid-template-columns: auto auto auto auto;
    // }
    // & .cardsItem {
    //   grid-template-columns: 1fr 1fr;
    // }
    height: $height;
    grid-template-areas:
      'carousel carousel carousel notes'
      'info info info notes';
    grid-template-columns: 0.95fr 0.95fr 0.95fr minmax(min-content, 1.15fr);
    grid-template-rows: 300px auto  ;
    & .cardsItem {
      grid-template-columns:  1fr 1fr 1fr;
    }
  }
  @include for-big-desktop-up {
    height: $height;
    grid-template-areas:
      'carousel carousel carousel notes'
      'info info info notes';
    grid-template-columns: 0.95fr 0.95fr 0.95fr minmax(min-content, 1.15fr);
    grid-template-rows: 300px auto;

    & .cardsItem {
      grid-template-columns:  1fr 1fr 1fr;
    }
  }
}