@import 'styles/main';

.modal {
  z-index: 999;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  overflow: hidden;
  & .backdrop {
    background-color: $black;
    opacity: 0.6;
    height: 100%;
    width: 100%;
  }
  & .modalCard {
    background-color: $white;
    position: absolute;
    height: auto;
    min-height: 400px;
    &.modalXLarger {
      min-height: 70vh;
    }
    max-height: calc(100vh - 100px);
    // width: auto;
    overflow-y: hidden;
    border-radius: 15px;
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
    padding: 20px 20px 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    & .closeButton {
      background-color: transparent;
      border: none;
      cursor: pointer;
      z-index: 999;
      & svg {
        height: 14px;

        & * {
          fill: $grey-light;
        }
      }
      &:hover > svg * {
        fill: $grey;
      }
    }
    & .modalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    & .modalContent {
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      flex-grow: 1;
      &.isLoading {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 100vh;
        height: 100%;
        // height: 100%;
      }
      & .modalLoader {
        svg {
          width: 200px;
        }

        // height: 200px;
      }
    }
    // sizes
    &.modalAuto {
      // min-width: 100%;
      min-width: 450px;
      min-height: auto;
    }
    &.modalSmall {
      width: 450px;
    }
    &.modalMedium {
      width: 650px;
    }
    &.modalLarge {
      width: 800px;
    }
    &.modalLarger {
      width: 1080px;
    }
    &.modalXLarger {
      width: 95%;
    }
    &.alert {
      min-height: auto;
    }
  }

  @include for-tablet-portrait-down {
    & .modalCard {
      &.modalAuto,
      &.modalSmall,
      &.modalMedium,
      &.modalLarge,
      &.modalLarger,
      &.modalXLarger {
        min-width: auto !important;
        width: 95%;
        min-height: auto !important;
      }
    }
  }
  @include for-tablet-portrait-up {
    & .modalCard {
      &.modalLarger {
        min-width: auto !important;
        width: 70%;
        min-height: auto !important;
      }
    }
  }

  & .title {
    @extend %reset;
    font-size: 20px;
    color: $primary-color;
    font-weight: 600;
  }
  & .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  &.active {
    display: flex;
  }
}
.modalFooter {
  margin-top: 10px;
}
