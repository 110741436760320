@import 'styles/main';
@import 'components/Button/button.module.scss';

.cardContainer {
  height: 100%;
  & .showScrollbar {
    -ms-overflow-style: auto !important; /* IE and Edge */
    scrollbar-width: auto !important; /* Firefox */
  }

  & .card {
    width: 100%;
    border-radius: 15px;
    padding: 20px 35px;
    overflow: auto;
    min-height: 150px;

    & .description {
      line-height: 1;
      font-weight: 300;
      padding-top: 8%;
      & .number {
        font-size: 35px;
        line-height: 0;
      }
    }

    &.cardValues {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .leftContent {
        width: 100%;
        height: 100%;

        &.adjust {
          display: flex;
          flex-flow: column;
          & div:first-child {
            flex-grow: 1;
          }
        }
      }
    }

    // icon
    & .icon {
      color: white;
      background-color: $primary-color-emphasis-light;
      border-radius: 50%;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 28px;
        height: 28px;
      }
    }

    // types
    &.primaryDarkened {
      background-color: $dark-color-card;
      color: white;
    }
    &.primaryLight {
      background-color: $light-color-card;
      color: white;
      & .icon {
        background-color: $primary-color-emphasis-light;
      }
    }
    &.primaryNavy {
      background-color: $primary-color-navy;
      color: white;
      & .icon {
        background-color: $primary-color-navy;
      }
    }
    &.default {
      background-color: white;
      & .icon {
        color: $primary-color-darkened;
        background-color: $primary-color-light;
      }
    }

    // full
    &.cardFull {
      padding: 0px;
      background-color: transparent;
      border-radius: 0px;
      min-height: auto;
    }
    // fill the rest of the space
    &.cardFill {
      // height: 100%;
      // align-items: flex-start;
      height: 100%;
      &.titleOutside {
        height: calc(100% - 33px);
      }
    }
  }
  // card as a container
  &.container {
    height: calc(100% - 55px);
  }

  & .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & span {
      font-size: calc(#{$font-size-note} + 8px);
      font-weight: 600;
    }
    &.primaryTitle {
      color: $primary-color;
    }

    // line-height: 1;
  }
  & .leftOptions,
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    > * {
      flex-grow: 1;
    }
  }
  & .titleOptions {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      margin-top: 2px;
    }
  }
  & .titleOptionsSide {
    margin-left: auto;
    margin-right: 20px;
    font-weight: 400;
  }

  .subtitle {
    margin-bottom: 15px;
    & div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      & span {
        font-size: calc(#{$font-size-note} + 6px);
        font-weight: 600;
        line-height: 1;
      }
    }
    &.primaryTitle {
      & div:first-child {
        & span {
          color: $primary-color;
        }
      }
    }
    & hr {
      width: 100%;
      border-bottom: 0;
      border-top: 0;
      // border-top: 1px dashed $grey-lighter;
    }
  }
  .subtitleWithHR {
    & div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      & span {
        font-size: calc(#{$font-size-note} + 4px);
        font-weight: 600;
        line-height: 1;
      }
    }
  }
  .withHr {
    height: 100%;
    color: $primary-color-darkened;
    border: 1px solid $primary-color-darkened;
    border-radius: 5px;
  }
}
