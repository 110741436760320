@import 'styles/main';

.tasksSection {
  min-width: 310px;
  // height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & .tasks {
    flex-grow: 1;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    & > div:not(:last-child) {
      margin-bottom: 10px;
      border-bottom: 1px dashed $grey-lighter;
    }
    & .emptyNotes {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $grey;
      line-height: 0.5;
    }
  }
  & .statuses {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;
    & div.status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      & span {
        font-size: calc(#{$font-size-body} - 1px);
        font-weight: 500;
      }
      & div {
        height: 11px;
        width: 11px;
        border-radius: 100%;
      }
      &:first-child {
        & div {
          line-height: 2;
          background-color: rgba(
            $color: $secondary-color-darkened,
            $alpha: 0.9
          );
        }
      }
      &:last-child {
        & div {
          line-height: 2;
          background-color: rgba(
            $color: $semantic-danger,
            $alpha: 0.7
          ) !important;
        }
      }
    }
  }
  @include for-tablet-portrait-down {
    min-width: auto;
  }
}
