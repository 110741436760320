@import 'styles/main';

.leads {
  &Modal {
    & > div:nth-child(2) {
      width: 60vw;
      min-height: 500px;
      height: min-content;
      max-height: 80vh;
    }
  }

  & .messageContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
      font-size: $font-size-title;
      color: $grey-light;
      font-weight: 600;
    }
    & button {
      font-size: 18px;
    }
  }

  @include for-tablet-portrait-down {
    .notice {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      line-height: 0;
      gap: 3px;
    }
  }
  @include for-tablet-portrait-up {
    .notice {
      z-index: 10;
      position: absolute;
      line-height: 0;
      top: 0px;
    }
  }
  @include for-tablet-landscape-up {
    .notice {
      z-index: 10;
      position: absolute;
      top: 13px;
      align-items: center;
      display: flex;
      gap: 10px;

      & p {
        border: none;
        color: $grey;
        font-weight: 400;
      }
    }
  }
}
.emailModal {
  & > div:nth-child(2) {
    max-height: min-content !important;
    min-height: min-content !important;
    height: min-content !important;
  }
}
