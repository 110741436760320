@import 'styles/main';

.spansContainer {
  padding-top: 2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  height: 220px;
  overflow-y: auto;
  gap: 10px;
}

.labelsPending {
  height: calc($font-size-body + 8px);
  font-size: calc($font-size-body - 2px);
  margin: 0 1% 0 1%;
  background-color: $semantic-warning !important;
  color: $warning-contrast !important;
  padding: 2px 8px;
  border-radius: 15px;
  font-weight: 400;
}

.labelsConfirmed {
  height: calc($font-size-body + 8px);
  font-size: calc($font-size-body - 2px);
  margin: 0 1% 0 1%;
  background-color: $secondary-color-light;
  color: $secondary-color-darkest;
  padding: 2px 8px;
  border-radius: 15px;
  font-weight: 400;
}

.longText {
  font-size: calc($font-size-body - 4px);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding-top: 21px;
}
