@import 'styles/main';

.agentInfoView {
  display: flex;
  flex-direction: column;
  gap: 15px;
  & .panel {
    height: 100%;
    overflow-y: auto;
  }
  & form p {
    color: $grey-light;
    font-weight: 400;
  }
  & input {
    border: none;
    padding: 2px 10px;
    color: $grey;
    transition: 300ms all;
    &:disabled {
      padding-left: 0;
    }
  }
  & div.title {
    color: $primary-color;
    letter-spacing: 1px;
    font-size: calc(#{$font-size-note} + 6px);
    font-weight: 500;
    margin-bottom: 20px;
  }
  & div.subTitle {
    color: black;
    letter-spacing: 1px;
    font-size: calc(#{$font-size-body});
    font-weight: 500;
  }
}

.personalInfo {
  display: grid;
  position: relative;
  grid-template-columns: 0.3fr 1fr;
  & .leftImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;
  }
  & .imageContainer {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    & .profilePicLabel {
      background-color: rgba($color: #000, $alpha: 0.6);
      z-index: 2;
      position: absolute;
      height: 100%;
      width: 100%;
      cursor: pointer;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: $font-size-body;
      width: 100%;
      padding: 0 5px;
      text-align: center;
      opacity: 0;
      transition: 300ms all;
      & svg {
        width: 15%;
        margin-bottom: 10px;
        & * {
          fill: #fff;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
    & .profilePicInput {
      display: none;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.placeholder {
        height: auto !important;
      }
    }
  }

  & > div:not(.imageContainer) {
    padding-left: 20px;
    margin-left: 20px;
    width: 100%;
  }

  @include for-tablet-portrait-down {
    & .imageContainer {
      width: 160px;
      height: 160px;
    }
    grid-template-columns: 1fr;
    & > div:not(.imageContainer) {
      padding-left: 0px;
      margin-left: 0px;
      width: 100%;
    }
  }
}

.modal {
  & div:nth-child(2) {
    min-height: 0px;
    height: min-content !important;
  }
}
