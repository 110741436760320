@import '../FormWithEditButton/formWithEditButton.module.scss';

.bankAccountForm {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.withButton {
  position: relative;

}

