@import 'styles/main';

.modalForm {
  & button {
    text-transform: capitalize;
  }
  & p {
    @extend %reset;
  }
  & .fieldName {
    font-size: $font-size-body;
    font-weight: 600;
    color: black;
  }

  & .dates {
    display: flex;
    margin-bottom: 15px;
    justify-content: flex-start;
    gap: 20px;
    & > span {
      font-size: $font-size-body;
    }
  }

  & .status {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  & .description {
    height: max-content;
    max-height: 250px !important;
    overflow-y: auto;
    background-color: $grey-lightest;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  & .externalLink {
    display: flex;
    align-items: center;
    gap: 10px;
    & svg {
      height: 12px;
      position: relative;
    }
  }
}
