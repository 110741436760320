@import 'styles/main';

.toggleSideNavButton {
  border-radius: 100%;
  transition: all;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  &:active {
    transform: scale(0.95);
  }
  > svg {
    font-size: 24px;
    fill: $grey;
  }
  &:hover {
    cursor: pointer;
    background-color: $grey-lightest;
    > svg {
      fill: $primary-color;
    }
  }
}
