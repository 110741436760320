@import 'styles/main';

.filesContainer {
  width: 100%;
  height: calc(100vh - 75px);
  background-color: white;
  border-radius: 15px;
  & iframe {
    position: relative;
    z-index: 1;
    border-radius: 15px;
    height: 99%;
  }
}
