@import 'styles/main';

.appointmentCard {
  min-width: 170px;
  height: 85px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 100ms all;
  &:hover {
    filter: brightness(95%);
  }
  & h2 {
    @extend %reset;
    font-family: $font-family-primary;
    padding: 2px 10px 0px 10px;
    flex-grow: 0;
    font-size: calc($font-size-subtitle - 3px);
    color: $grey;
    font-weight: 600;
  }
  & > div {
    padding: 8px 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-top: 1px dashed;
    &.withCounter {
      // border-color: transparent !important;
    }
    & .subtitle {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-body;
      // font-weight: 500;
      // padding-top: 8px;
    }
    & .counter {
      display: flex;
      flex-direction: column;
      height: max-content;
      & span {
        @extend %reset;
        font-size: 0.9em;
      }
      & .number {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: -8px;
      }
    }
  }
}

.confirmed {
  background-color: $secondary-color-light;
  & > div {
    // border-color: darken($color: #e1f6fe, $amount: 5);
    & .counter .number {
      color: $primary-color;
    }
  }
  &.active {
    background-color: $primary-color-emphasis-light !important;
    & *,
    & .counter * {
      color: #fff !important;
    }
    .hr {
      border-top: 1px solid white;
    }

  }
}
.pending {
  background-color: #ffe9d4 !important;

  & .counter .number {
    color: $warning-contrast;
  }
  &.active {
    // background-color: #f89939 !important;
    background-color: $primary-color-emphasis-light !important;
    & *,
    & .counter * {
      color: #fff !important;
    }
    .hr {
      border-top: 1px solid white;
    }

  }
}
.notRequested {
  background-color: $grey-lightest;

  &.active {
    // background-color: $grey !important;
    background-color: $primary-color-emphasis-light !important;
    & *,
    & .counter * {
      color: #fff !important;
    }
    .hr {
      border-top: 1px solid white;
    }

  }
}

.hr {
  width: 100%;
  margin: 0px;
  border: none;
  border-top: 1px solid $grey;
}