@import 'styles/main';

.infoItem {
  grid-area: info;
}
.generalItem {
  grid-area: general;
}
.appointmentsItem {
  grid-area: appointments;
}
.agentsItem {
  grid-area: agents;
}
.agenciesItem {
  grid-area: agencieslist;
}

.dashboard {
  height: calc(100vh - 75px);
  display: grid;
  gap: 20px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  & .cardContent span {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    font-size: large;
    line-height: 1.6;
    font-weight: 400;
  }
  
  & .memberAgencies {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;

    button {
      div {
        white-space: nowrap;
      }
    }
    & .separator {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: $grey-light;
        font-size: $font-size-note;
      }
      svg {
        color: $grey-light;
      }
    }
  }

  @include for-tablet-portrait-down {
    grid-template-areas:
      'agencieslist'
      'info'
      'general'
      'appointments'
      'agents';
    grid-template-columns: minmax(auto, 100%);
    grid-template-rows: max-content;
  }
  @include for-tablet-portrait-up {
    grid-template-areas:
      'agencieslist agencieslist'
      'info general '
      'appointments appointments'
      'agents agents';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1fr;
  }
  @include for-tablet-landscape-up {
    grid-template-areas:
      'agencieslist agencieslist agencieslist'
      'info general appointments'
      'agents agents agents';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto 1fr;
  }
  @include for-desktop-up {
    grid-template-areas:
      'agencieslist agencieslist agencieslist'
      'info general appointments'
      'agents agents agents';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto 1fr;
  }
}
