@import 'styles/main';

.footer {
  flex-grow: 1;
  position: absolute;
  bottom: -20px;
  left: 0px;
  width: 90%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  & span {
    height: 2px;
    flex-grow: 1;
    background-color: $white;
    margin-right: 20px;
  }
  & img {
    filter: grayscale(100%);
    width: 200px;
    margin-left: auto;
  }
}
