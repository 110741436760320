@import 'styles/main';

.inputWithCopyButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  // & .copyBtn {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 8px;
  //   background-color: $primary-color-light;
  //   padding: 2px 20px;
  //   border-radius: 10px;
  //   color: $primary-color-emphasis-light;
  //   font-weight: 600;
  //   text-transform: lowercase;
  //   height: max-content;
  //   position: relative;
  //   cursor: pointer;
  //   transition: 200ms all;
  //   &:hover {
  //     background-color: $primary-color-emphasis-light;
  //     color: $white;
  //   }
  // }
  & input {
    width: auto;
  }
  & p {
    font-size: 12px;
    font-weight: 400 !important;
  }
  .copied {
    & input {
      animation: copied 1s linear;
    }
  }
}

@keyframes copied {
  0% {
    background-color: darken($color: $input-background, $amount: 20);
  }
  100% {
    background-color: $input-background;
  }
}

.button{
  margin-right: 20px;
}