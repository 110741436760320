html,
body {
  @extend %reset;
  font-size: $font-size-primary;
  color: $black;
  background: $grey-background;
  overflow-x: hidden;
  & * {
    box-sizing: border-box;
    font-family: $font-family-primary;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
      background-color: $grey-lightest;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: $grey-light;
      border-radius: 5px;
    }
    &::-webkit-scrollbar:hover,
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }
    @include for-phone-only {
      &::-webkit-scrollbar-thumb {
        background: $grey-light;
        border-radius: 5px;
        display: none;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          display: block;
        }
      }
    }
  }

  height: 100%;
}

a {
  text-decoration: none;
  color: $primary-color;

  outline: none;
  width: max-content;
}
* {
  letter-spacing: 0.2px;
}

select {
  border: 1.5px solid $grey-lighter;
  border-radius: 5px;
  // background: $input-background;
  outline: none !important;
  padding: 13px 15px;
  font-size: $font-size-body;
  font-weight: 400;
  color: black;
  display: block;
  letter-spacing: 0.8px;
  &::placeholder {
    color: $grey-light;
  }
  &:focus {
    border: 1.5px solid $primary-color-darkened;
  }
  &:disabled {
    background-color: transparent;
  }
}

textarea {
  resize: none;
}
