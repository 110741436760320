@import 'styles/main';

.container {
  & p {
    font-size: 20px;
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
  & button {
    margin: 30px auto 0;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-top: 30px;
  & button {
    margin: 0 10px;
  }
}
