@import 'styles/main';

.loader {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: 0;
  background-color: rgba(247, 249, 251, 0.85);
  overflow: hidden;
  & img {
    display: block;
    margin: 0 auto;
    width: 150px;
    animation: logoJumping infinite 1s;
  }
  & svg {
    width: 40px;
    animation: logoJumping infinite 2s;
  }

  &.global {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    & svg {
      width: 50px;
    }
  }
  @include for-tablet-portrait-down {
    &.global {
      & svg {
        width: 250px;
      }
    }
    & svg {
      width: 250px !important;
    }
  }
}

@keyframes logoJumping {
  0% {
    // transform: scale(0.99);
    opacity: 0.6;
  }
  50% {
    // transform: scale(1);
    opacity: 1;
  }
  100% {
    // transform: translateY(0.99);
    opacity: 0.6;
  }
}
