@import 'styles/main';



.pageHeader {
  // flex-grow: 1;
  // position: absolute;
  // top: -15px;
  // right: 0;
  // height: 50px;
  // padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  flex-grow: 1;
  margin-bottom: 5px;
  // width: min-content;
  & .line {
    height: 2px;
    flex-grow: 1;
    display: block;
    margin-right: 10px;
  }

  & .welcomLabel {
    line-height: 1.3;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      color: $grey;
    }
    & .viewAsAgent {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $secondary-color-darkened !important;
      & svg {
        width: 22px;
        height: 22px;
      }
    }
    span:first-child {
      color: black;
      font-size: calc(#{$font-size-body} + 2px);
      font-weight: 500;
    }
    & .toggleSideBar {
      display: block;
      & > div {
        background-color: darken($color: $grey-lightest, $amount: 1);
      }
      svg {
        width: 26px;
        height: 26px;
      }
    }

    @include for-phone-only {
      & .viewAsAgentName {
        display: block;
        width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @include for-tablet-portrait-up {
      & .toggleSideBar {
        display: none;
      }
    }
  }

  & .menuContainer {
    height: 100%;
    width: fit-content;
    margin-left: auto;
    padding: 5px 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &::before {
      content: '';
      position: absolute;
      background-color: $white;
      height: 20px;
      width: 100%;
      bottom: -19px;
      left: 0;
      opacity: 0;
    }
    & img {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 50%;
      display: block;
      margin: 0 auto;
    }
    & .nameContainer {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      line-height: 1.2;
      & .name {
        font-weight: 600;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
      & .permissions {
        font-size: 13px;
        font-weight: 500;
        color: $grey-light;
      }
    }

    & .dropdownMenu {
      background-color: $white;
      z-index: 80;
      position: absolute;
      bottom: -5px;
      transform: translateY(100%);
      right: 0;
      width: 220px;
      overflow: hidden;
      opacity: 0;
      display: none;
      border-radius: 5px;
      box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.35);
      & .dropdownLink {
        &:last-child {
          & * {
            color: $semantic-danger;
            fill: $semantic-danger;
          }
        }
      }
      & > a {
        &:hover {
          background-color: $grey-lightest;
        }

        border-bottom: 1px dashed $grey-lighter;

        &:last-child {
          border-bottom: none;
        }
        padding: 12px 10px;
      }
    }
  }
  &.active {
    z-index: 2;
    & .menuContainer {
      background-color: $white;
      box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.35);
      &::before {
        opacity: 1;
      }
      & .dropdownMenu {
        display: block;
        opacity: 1;
      }
    }
  }

  &.fullHeader {
    width: 100%;
    left: 0;
    right: auto;
    & .line {
      background-color: $white;
    }
  }

  @include for-tablet-portrait-down {
    & .welcomLabel {
      line-height: 1;
      span {
        font-size: calc(#{$font-size-note});
      }
      span:first-child {
        color: black;
        font-size: calc(#{$font-size-body} - 2px);
        font-weight: 500;
      }
    }
    & .menuContainer {
      & .name {
        font-size: $font-size-note;
      }
      & img {
        height: 35px;
        width: 35px;
      }
      & .permissions {
        font-size: 10px;
      }
    }
  }
}
