@import 'styles/main';

.contactCard {
  width: 80%;
  &:first-child {
    margin-bottom: 20px;
  }
  & h3 {
    @extend %reset;
    font-size: 16px;
    color: $grey-light;
    font-weight: 400;
  }
  & button {
    padding: 2px 40px;
    text-transform: capitalize;
  }
  & > div {
    display: flex;
    align-items: center;
    margin-top: 5px;
    & a {
      margin-right: 15px;
    }
    & span {
      font-weight: 600;
    }
  }
}
