.residentForm {
  width: 100%;
  position: relative;
  & .withCopy {
    position: relative;
    & span {
      position: absolute;
      right: 0%;
    }
  }
}
