@import 'styles/main';

.links {
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &:last-child {
      margin-top: 20px;
    }
  }
  & span {
    color: $grey;
  }
  & a {
    color: $primary-color;
    font-weight: 500;
    transition: 200ms all;
    &:hover {
      color: $primary-color-emphasis-light;
    }
  }
  & .forgotPassword {
    margin-top: 8px;
  }
}
