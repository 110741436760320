@import 'styles/main';
.dateComponent {
    padding: 2px;
    margin-bottom: 5px;
  
    .header {
      display: flex;
      align-items: center;
  
      .icon {
        height: 13px;
        width: 13px;
        color: $secondary-color;
      }
  
      .title {
       margin-left: 5%;
       font-size:$font-size-body;
       font-weight: 600;
       font-family: $font-family-primary;
      }
    }
  
    .datetime {
    color: $grey;
    font-size:$font-size-note;
    font-family: $font-family-primary;
    }
  
    .description {
    font-size:$font-size-note;
    }
    .URL{
      font-size:$font-size-note;
      margin:0px  !important;
    }
  }
  