@import 'styles/main';

.tabs {
  width: 100%;
  .headersContainer {
    hr {
      border: 1px solid $grey-lightest;
    }
    position: relative;
    & .scrollLeft {
      display: none;
      position: absolute;
      z-index: 1;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.58) 100%
      );
      width: 23px;
      height: 100%;
      top: 0;
      & button {
        transform: translate(0px, calc(50% - 3px));
      }
      &.disabled {
        opacity: 0.3;
      }
    }
    & .scrollRight {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 23px;
      height: 100%;
      z-index: 1;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.58) 100%
      );
      & button {
        transform: translate(0px, calc(50% - 3px));
      }
      &.disabled {
        opacity: 0.3;
      }
    }

    & .headers {
      display: flex;
      // align-items: center;
      // flex-wrap: wrap;
      overflow: auto;
      gap: 5px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      padding: 5px 0px;
      @extend .no-scrollbar;
      position: relative;
      scroll-behavior: smooth;
      & .header {
        // flex-grow: 1;
        white-space: nowrap;
        padding: 5px 20px;
        color: $grey;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
        font-size: calc(#{$font-size-body} - 1px);
        border-radius: 20px;
        &.active {
          font-weight: 500;
          color: $primary-color-emphasis;
          background-color: $primary-color-light;
        }
        @include for-tablet-landscape-up {
          flex-grow: 0;
        }
      }
      &.small {
        & .header {
          font-size: calc(#{$font-size-note});
        }
      }
    }

    &.scrollable {
      & .scrollLeft,
      .scrollRight {
        display: block;
      }
      & .headers {
        padding: 5px 25px !important;
      }
    }
  }

  .headersContainerFloating{
    position: absolute;
    display: none;
    
    &.floatingHeader {
      display: block;
      background-color: white !important;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0px 10px;
      box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.35);
      transition: 0.2s all;
    }
  }

  .body {
    & > div {
      box-shadow: none;
    }
  }
}


