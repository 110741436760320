.codeElement {
  border: 1px solid;
  border-radius: 5px;
  height: 3rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;
  color: #4299e1;
  caret-color: transparent;
  user-select: none;
  padding-top: 0.125rem;
  overflow: hidden;
  :focus {
    border: 4px solid;
    outline: 0.125rem solid #4299e1;
    outline-offset: 0;
  }
}
.codeContainer {
  width: 100%;
  display: flex;
  justify-content:space-between;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.codeElementDisabled {
  opacity: 0.6;
}
