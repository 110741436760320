@import 'styles/main';

.cellContainer {
  font-size: 13px;
  color: $grey;
  // font-weight: 600;
}

.checkMark {
  text-align: center;
}
