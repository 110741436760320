@import 'styles/main';

.button {
  display: block;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  outline: none !important;
  cursor: pointer;
  transition: 200ms all;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  &.fill {
    width: 100%;
  }
  & .text {
    padding-top: 2px;
  }
  &:active {
    transform: scale(0.99);
  }
  //types
  &.primary {
    background: $secondary-color;
    color: $white;
    // text-shadow: 0 0 5px darken($color: $secondary-color, $amount: 10);
    svg {
      fill: currentColor !important;
    }
    &:hover {
      background: darken($color: $secondary-color, $amount: 10);
    }
    &.outlined {
      background-color: $secondary-color-light;
      color: $secondary-color-darkest;
      text-shadow: none;
    }
  }
  &.primaryDarkened {
    background: $second-button-card;
    color: white;
    &:hover {
      background: darken($color: $second-button-card, $amount: 5);
      color: white;
    }
  }
  &.primaryLight {
    background: $primary-color-emphasis-light;
    color: white;
    &:hover {
      background: darken($color: $primary-color-emphasis-light, $amount: 5);
      color: white;
    }
  }
  &.secondary {
    background: $primary-color-light;
    color: $primary-color-darkened;
    svg {
      fill: currentColor;
    }
    &:hover {
      background: darken($color: $primary-color-light, $amount: 5);
      color: $primary-color;
      svg {
        fill: $primary-color;
      }
    }
  }
  &.danger {
    background-color: $semantic-danger;
    color: white;
    &:hover {
      background-color: darken($color: $semantic-danger, $amount: 15);
    }
    &:disabled {
      background-color: $grey-lighter;
      color: $grey;
    }
    &.outlined {
      background-color: rgba($color: $semantic-danger, $alpha: 0.2);
      color: $semantic-danger;
    }
  }
  &.transparent {
    background: transparent;
    color: black;
    &:hover {
      background: $primary-color-light;
    }
  }
  &.default {
    background: $grey;
    color: white;
    &:hover {
      background: darken($color: $grey, $amount: 10);
    }
    &.outlined {
      background-color: #eaeaea;
      color: black;
      &:hover {
        background: darken($color: #eaeaea, $amount: 10);
      }
    }
  }
  &:disabled {
    background-color: $grey-lighter !important;
    color: $grey !important;
    cursor: not-allowed;
    &:hover {
      background-color: $grey-lighter !important;
      color: $grey !important;
    }
  }

  // sizes
  &.xsmall {
    padding: 1px 10px;
    font-size: calc(#{$font-size-body} - 2px);
    & svg {
      height: 15px !important;
      width: 15px !important;
    }
    &.onlyIcon {
      padding: 0px;
    }
  }
  &.small {
    padding: 2px 15px;
    font-size: calc(#{$font-size-body} - 1px);
    & svg {
      height: 19px !important;
      width: 19px !important;
    }
    &.onlyIcon {
      padding: 2px;
    }
  }
  &.medium {
    padding: 5px 30px;
    font-size: calc(#{$font-size-body});
    & svg {
      height: 20px !important;
      width: 20px !important;
    }
    &.onlyIcon {
      padding: 7px;
    }
  }
  &.large {
    padding: 9px 45px;
    font-size: calc(#{$font-size-body} + 2px);
    & svg {
      height: 22px !important;
      width: 22px !important;
    }
    &.onlyIcon {
      padding: 9px;
    }
  }

  &.roundedSmall {
    border-radius: 5px;
  }
  &.roundedMedium {
    border-radius: 15px;
  }
  &.roundedLarge {
    border-radius: 25px;
  }
}

.open {
  background-color: $secondary-color;
  color: $white !important;
  cursor: default;
  background-color: red;
  &.hoverable:not(:disabled) {
    cursor: pointer;
    &:hover {
      background: darken($color: $secondary-color, $amount: 10) !important;
    }
  }
  &.outlined {
    background-color: $secondary-color-light;
    color: $secondary-color-darkest !important;
  }
}
.self {
  background-color: $grey-lightest !important;
  color: $black !important;
  cursor: default;
  &.hoverable:not(:disabled) {
    cursor: pointer;
    &:hover {
      background-color: $grey-lighter !important;
    }
  }
}
.oNeill {
  background-color: $primary-color-light !important;
  color: $primary-color-darkened !important;
  cursor: default;
  &.hoverable:not(:disabled) {
    cursor: pointer;
    &:hover {
      color: $white !important;
      background-color: $primary-color-darkened !important;
    }
  }
}
.inReview {
  background-color: $semantic-warning !important;
  color: $warning-contrast !important;
  cursor: default;
  &.hoverable:not(:disabled) {
    cursor: pointer;
    &:hover {
      color: $white !important;
      background-color: $warning-contrast !important;
    }
  }
}
.closed {
  background-color: $semantic-danger;
  color: $white !important;
  cursor: default;
  &.hoverable:not(:disabled) {
    cursor: pointer;
    &:hover {
      color: $white !important;
      background-color: darken(
        $color: $semantic-danger,
        $amount: 20
      ) !important;
    }
  }
  &.outlined {
    background-color: rgba($color: $semantic-danger, $alpha: 0.2) !important;
    color: $semantic-danger !important;
  }
}
