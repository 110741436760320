@import 'styles/main';

.myAppointments {
  height: calc(100vh - 75px);
  display: grid;
  gap: 15px;
  position: relative;

  & .myAppointmentsItem {
    grid-area: myappointments;
    overflow: auto;
    position: relative;
    display: block;
  }

  & .detailsItem {
    grid-area: details;
    overflow: auto;
    display: block;
  }

  @include for-tablet-portrait-down {
    .detailsItem {
      display: none;
    }
    grid-template-areas: 'myappointments';
    &.isActive {
      grid-template-areas: 'details';
      & .myAppointmentsItem {
        display: none;
      }
      & .detailsItem {
        display: block;
      }
    }
  }
  @include for-tablet-portrait-up {
    .detailsItem {
      display: none;
    }
    grid-template-areas: 'myappointments';
    &.isActive {
      grid-template-areas: 'details';
      & .myAppointmentsItem {
        display: none;
      }
      & .detailsItem {
        display: block;
      }
    }
  }
  @include for-tablet-landscape-up {
    grid-template-areas: 'myappointments details' !important;
    grid-template-columns: 1fr minmax(auto, 400px) !important;
    grid-template-rows: 1fr !important;
    & .detailsItem {
      display: block !important;
    }
    & .myAppointmentsItem {
      display: block !important;
    }
  }
  @include for-desktop-up {

    grid-template-areas: 'myappointments details';
    grid-template-columns: 1fr minmax(auto, 600px);
    grid-template-rows: 1fr;
  }

  & .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; 
  @media (max-width: 768px) {
    flex-direction: column; 
    padding-top: 10px !important;
  }
}

.button {
  width: auto;
  @media (max-width: 768px) { 
  width: 100%;
  }
}

.sunshineMessageContainer{
  height: calc(100vh - 75px);
  background-color: white !important;
  border-radius: 5px;
  display: flex;
  align-items: center !important;
}
.sunshineMessage{
  display: flex;
  align-self: center;
  padding: 10%;
  font-size: $font-size-header;
}

